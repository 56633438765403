import { FeaturedPlugin, NewPlugin } from 'api/plugins';

type PluginIcons = NewPlugin['icons'] | FeaturedPlugin['icons'];

export const getPluginIconSrcSet = (icons: PluginIcons) => {
  return (['1x', '2x'] as const)
    .filter(size => icons?.[size])
    .map(size => `${icons?.[size]} ${size}`)
    .join(', ');
};
