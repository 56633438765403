import { useTranslation } from 'react-i18next';

export const useBuildSelectOptions = () => {
  const { t } = useTranslation();

  return {
    buildOptions: <T extends string>(...options: T[]) => {
      return options.map(option => ({
        label: t(option),
        value: option,
      }));
    },
  };
};
