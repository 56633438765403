import { useTranslation } from 'react-i18next';
import { toASCII, toUnicode } from 'punycode';

export const useDomainHelperText = (domain = '') => {
  const { t } = useTranslation();

  const punycodeDomain = toASCII(domain);
  const idnDomain = toUnicode(domain);
  if (!domain || idnDomain === punycodeDomain) {
    return '';
  } else if (domain.startsWith('xn--')) {
    return `${t('idn')}: ${idnDomain}`;
  } else {
    return `${t('punycode')}: ${punycodeDomain}`;
  }
};
